<template>
  <tp-modal
    name="modal-add-role-server"
    max-width="560px"
    v-slot="payload"
    @cancel="closeModal"
  >
    <v-card class="border-0">
      <div class="card px-5 py-3 align-center d-flex" height="56x">
        <div class="font-weight-bold">Thêm ghi nhận phục vụ</div>

        <v-spacer></v-spacer>

        <!--        <v-btn icon @click="closeModal()">-->
        <!--          <v-icon>mdi-close</v-icon>-->
        <!--        </v-btn>-->
      </div>
    </v-card>

    <!--        Start: actions-->
    <div
      class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
    >
      <v-card class="pa-5">
        <!--        Start: body -->
        <div class="mb-4">
          <v-autocomplete
            v-model="dataConfirm.role"
            class="text-body-1 text-right"
            hide-selected
            clearable
            dense
            flat
            hide-details
            :items="rolesName"
            item-text="text"
            item-value="value"
            no-data-text="Không có dữ liệu"
            placeholder="Kỹ thuật tiếp nhận"
            outlined
            single-line
            @change="dataConfirm.value = null"
          />
        </div>
        <div
          class="mb-4"
          v-if="
            dataConfirm && dataConfirm.role && dataConfirm.role === 'SHIPPING'
          "
        >
          <v-text-field
            v-model="dataConfirm.value"
            class="text-body-1"
            clearable
            dense
            hide-details="auto"
            outlined
            placeholder="Nhập số quãng đường (km)"
          ></v-text-field>
        </div>
        <div class="mb-4">
          <v-autocomplete
            v-model="dataConfirm.user"
            class="text-body-1 text-right"
            hide-selected
            clearable
            dense
            flat
            hide-details
            :items="employeesSameUserWithBranch"
            item-text="name"
            item-value="id"
            :menu-props="{ maxWidth: '332px' }"
            no-data-text="Không có dữ liệu"
            placeholder="Nhân sự"
            outlined
            single-line
          />
        </div>
        <div class="mb-4">
          <v-textarea
            v-model="dataConfirm.note"
            class="text-body-1"
            dense
            hide-details="auto"
            placeholder="Mô tả"
            outlined
          ></v-textarea>
        </div>
        <div v-show="MUTILTIMES_ROLES.includes(dataConfirm.role)" class="mb-4">
          <v-text-field
            v-model.number="times"
            class="text-body-1"
            clearable
            dense
            hide-details="auto"
            placeholder="Số lần phục vụ"
            outlined
            type="number"
          ></v-text-field>
          <div class="text-body-2 grey--text text--darken-2 mt-1 ml-2">
            Nhập số lần cho phép tạo nhiều phục vụ cùng lúc.
          </div>
        </div>
        <div class="d-flex align-center justify-center">
          <v-btn
            class="rounded-lg mt-5 px-7"
            color="primary"
            depressed
            @click="
              addRole(
                payload.payload.model_id,
                payload.payload.model_name,
                payload.payload.dataNamespaced,
                payload.payload.dataRequest
              )
            "
            v-if="payload.payload.model_id && payload.payload.model_name"
            :loading="
              invoiceParticipantStatusRequest.value ===
                'loading-createInvoiceParticipant' ||
                invoiceParticipantStatusRequest.value ===
                  'loading-createManyInvoiceParticipant'
            "
            :disabled="
              invoiceParticipantStatusRequest.value ===
                'loading-createInvoiceParticipant' ||
                invoiceParticipantStatusRequest.value ===
                  'loading-createManyInvoiceParticipant'
            "
          >
            Xác nhận
          </v-btn>
        </div>
      </v-card>
    </div>
  </tp-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";

const MUTILTIMES_ROLES = ["TECHNIQUE", "TECHNIQUE_HARDWARE", "PACKAGING"];

export default {
  data() {
    return {
      times: null,
      dataConfirm: {
        role: null,
        note: null,
        value: null,
        user: this.user && this.user.id ? this.user.id : null
      },
      MUTILTIMES_ROLES
    };
  },

  computed: {
    ...mapGetters({
      employees: "EMPLOYEE/allEmployees",
      invoiceParticipantStatusRequest: "INVOICE_PARTICIPANT/statusRequest",
      user: "AUTHENTICATION/user",
      saleReceipt: "SALE_RECEIPT/saleReceipt",
      rolesName: "ROLE/rolesName",
      branch_id_model_add_role_server:
        "MODAL_SERIAL_PICKER/branch_id_model_add_role_server"
    }),

    employeesSameUserWithBranch() {
      let employeesSameBranchWithUsers = [];

      if (this.branch_id_model_add_role_server && this.employees) {
        this.employees.map(item => {
          if (item.branch_id === this.branch_id_model_add_role_server)
            employeesSameBranchWithUsers.unshift(item);
        });
        this.employees.map(item => {
          if (item.branch_id !== this.branch_id_model_add_role_server)
            employeesSameBranchWithUsers.push(item);
        });
        return employeesSameBranchWithUsers;
      } else return this.employees;
    }
  },

  watch: {
    "dataConfirm.role"(val) {
      if (![].includes(val)) {
        this.times = null;
      }
    }
  },

  methods: {
    closeModal() {
      this.dataConfirm = {
        role: null,
        note: null,
        value: null,
        user: this.user && this.user.id ? this.user.id : null
      };
      this.$modal.hide({ name: "modal-add-role-server" });
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setBranchIDModelAddRoleServer",
        null
      );
    },

    async addRole(model_id, model_name, dataNamespaced, dataRequest) {
      if (this.dataConfirm.value && parseInt(this.dataConfirm.value) > 30) {
        showModalAlertError(this, {
          title: "Không thể thêm phục vụ",
          message: "Quãng đường tối đa là 30km"
        });

        return;
      }

      const objSender = {
        model_id: model_id,
        model_name: model_name,
        user_id: this.dataConfirm.user,
        role: this.dataConfirm.role,
        note: this.dataConfirm.note,
        value: this.dataConfirm.value
      };

      if (this.times && Number(this.times) > 1) {
        const arr = Array.from({ length: Number(this.times) }, () => ({
          ...objSender
        }));

        await this.$store.dispatch(
          "INVOICE_PARTICIPANT/createManyInvoiceParticipant",
          arr
        );
      } else {
        await this.$store.dispatch(
          "INVOICE_PARTICIPANT/createInvoiceParticipant",
          objSender
        );
      }

      if (
        this.invoiceParticipantStatusRequest.value ===
          "success-createInvoiceParticipant" ||
        this.invoiceParticipantStatusRequest.value ===
          "success-createManyInvoiceParticipant"
      ) {
        if (dataNamespaced && dataRequest) {
          await this.$store.dispatch(dataNamespaced, dataRequest);
        }

        if (dataNamespaced === "SALE_RECEIPT/getSaleReceiptById") {
          await this.$store.dispatch("SALE/setSaleTabActive", {
            ...this.saleReceipt,
            branch_id: this.saleReceipt.branch.id,
            khachhang_id: this.saleReceipt.khachhang.id,
            type: 1
          });
        }

        this.closeModal();

        showToastActionAlert(this, { message: "Thêm phục vụ thành công" });
      }

      if (
        this.invoiceParticipantStatusRequest.value ===
          "error-createInvoiceParticipant" ||
        this.invoiceParticipantStatusRequest.value ===
          "error-createManyInvoiceParticipant"
      ) {
        showModalAlertError(this, {
          title: "Lỗi thêm phục vụ",
          message: this.invoiceParticipantStatusRequest.message
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.border-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-card {
  box-shadow: none !important;
}
.images {
  .image {
    padding-top: 50px;
    width: 50px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:hover .icon-remove-image {
      opacity: 1;
    }
    .icon-remove-image {
      opacity: 0;
      transition: all ease 0.2s;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      cursor: pointer;
      color: #868686;
      background: #ffffff4a;
      border: 1px solid #868686;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
</style>
